import Vue from 'vue'
import Vuex from 'vuex'
import { sortBy, max } from 'lodash-es'
import { getAnalytics, logEvent } from "firebase/analytics";

enum LocalStorageKeys {
  FavoriteIds = `FavoriteIds`
}

Vue.use(Vuex)

export interface WaitTimes {
  lands: Land[];
}

export interface Land {
  id: number;
  name: string;
  rides: Ride[];
}

export interface Ride {
  id: number;
  name: string;
  is_open: boolean;
  wait_time: number;
  last_updated: string;
}

export type DisplayLand = `all` | `dl` | `dca`;
export type DisplayMode = `lands` | `rides`;

const init_favorite_ids = localStorage.getItem(LocalStorageKeys.FavoriteIds);

export default new Vuex.Store({
  state: {
    wait_times: [] as WaitTimes[],
    favorite_ids: init_favorite_ids 
      ? JSON.parse(init_favorite_ids) 
      : {} as Record<number, boolean>,
    search_term: ``,
    current_date: new Date(),
    display_land: `all` as DisplayLand,
    display_mode: `lands` as DisplayMode,
  },
  mutations: {
    updateCurrentDate(state): void {
      state.current_date = new Date();
    },
    setWaitTimes(state, wait_times: WaitTimes[]): void {
      state.wait_times = wait_times;
    },
    addFavoriteId(state, id: number): void {
      state.favorite_ids = {
        ...state.favorite_ids,
        [id]: true,
      };
    },
    removeFavoriteId(state, id: number): void {
      const next_ids = {...state.favorite_ids};
      delete next_ids[id]

      state.favorite_ids = next_ids;
    },
    updateSearchTerm(state, term: string): void {
      state.search_term = term;
    },
    setDisplayLand(state, land: DisplayLand): void {
      state.display_land = land;
    },
    setDisplayMode(state, mode: DisplayMode): void {
      state.display_mode = mode;
    }
  },
  actions: {
    updateCurrentDate({commit}): void {
      commit(`updateCurrentDate`);
    },
    setWaitTimes({commit}, wait_times: WaitTimes[] ): void {
      commit(`setWaitTimes`, wait_times);
    },
    toggleFavoriteId({state, commit}, id: number) :void {
      const analytics = getAnalytics();

      if (state.favorite_ids[id]) {
        commit(`removeFavoriteId`, id);

        logEvent(analytics, `unfavorite_ride`, {
          id: String(id)
        });
      } else {
        commit(`addFavoriteId`, id);
        
        logEvent(analytics, `favorite_ride`, {
          id: String(id)
        });
      }

      localStorage.setItem(LocalStorageKeys.FavoriteIds, JSON.stringify(state.favorite_ids));
    },
    updateSearchTerm({commit}, term: string): void {
      commit(`updateSearchTerm`, term);
    },
    setDisplayLand({commit}, land: DisplayLand) {
      commit(`setDisplayLand`, land);
      
      const analytics = getAnalytics();
      
      logEvent(analytics, `set_display_land`, {
        land
      });
    },
    setDisplayMode({commit}, mode: DisplayMode) {
      commit(`setDisplayMode`, mode);
    },
  },
  modules: {
  },
  getters: {
    combined_lands(state): Land[]{
      const disneyland_lands = state?.wait_times[0]?.lands || [];
      const california_adventure_lands = state?.wait_times[1]?.lands || [];
      const combined_lands = [...disneyland_lands, ...california_adventure_lands];
      const sorted_lands = sortBy(combined_lands, [`name`])
      return sorted_lands || [];
    },
    combined_rides(state, getters): Ride[] {
      const rides = [] as Ride[];
      
      for (const land of getters.combined_lands) {
        for (const ride of land.rides) {
          rides.push(ride);
        }
      }

      return rides;
    },
    disneyland_lands(state): Land[] {
      return state?.wait_times[0]?.lands || [];
    },
    disneyland_rides(state, getters): Ride[] {
      const rides = [] as Ride[];
      
      for (const land of getters.disneyland_lands) {
        for (const ride of land.rides) {
          rides.push(ride);
        }
      }

      return rides;
    },
    california_adventure_lands(state): Land[] {
      return state?.wait_times[1]?.lands || [];
    },
    california_adventure_rides(state, getters): Ride[] {
      const rides = [] as Ride[];
      
      for (const land of getters.california_adventure_lands) {
        for (const ride of land.rides) {
          rides.push(ride);
        }
      }

      return rides;
    },
    latest_updated_date(state, getters): Date | null {
      const combined_lands = getters?.combined_lands;
      if (!combined_lands) return null;
      
      const updates = [] as Date[];

      for (const land of combined_lands) {
        for (const ride of land.rides) {
          const update = new Date(ride.last_updated);
          updates.push(update)
        }
      }

      return max(updates) as Date;
    },
    minutes_since_last_update(state, getters): number {
      if (!getters.latest_updated_date) { return 0; }
      
      const latest_updated_date: Date = getters.latest_updated_date;
      const diff = (state.current_date.getTime() - latest_updated_date.getTime()) / 1000 / 60;
      return Math.round(diff);
    },
    favorite_rides(state, getters): Ride[] {
      const favorite_rides = [] as Ride[];

      for (const land of getters.combined_lands as Land[]) {
        for (const ride of land.rides) {
          if (state.favorite_ids[ride.id]) {
            favorite_rides.push(ride);
          }
        }
      }

      // return favorite_rides;
      return sortBy(favorite_rides, [`name`]);
    },
    disneyland_favorite_rides(state, getters): Ride[] {
      const favorite_rides = [] as Ride[];

      for (const land of getters.disneyland_lands as Land[]) {
        for (const ride of land.rides) {
          if (state.favorite_ids[ride.id]) {
            favorite_rides.push(ride);
          }
        }
      }

      // return favorite_rides;
      return sortBy(favorite_rides, [`name`]);
    },
    california_adventure_favorite_rides(state, getters): Ride[] {
      const favorite_rides = [] as Ride[];

      for (const land of getters.california_adventure_lands as Land[]) {
        for (const ride of land.rides) {
          if (state.favorite_ids[ride.id]) {
            favorite_rides.push(ride);
          }
        }
      }

      // return favorite_rides;
      return sortBy(favorite_rides, [`name`]);
    },
  }
})
