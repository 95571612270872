import Vue from 'vue';
import store from '../../store';

export default Vue.extend({
  computed: {
    latest_updated_date(): Date | null {
      return store.getters.latest_updated_date;
    },
    latest_updated_time(): string{
      if (!this.latest_updated_date) { return ``; }

      const am_pm = this.latest_updated_date.getHours() > 11 ? `PM` : `AM`;

      const hr = this.latest_updated_date.getHours() > 12
        ? this.latest_updated_date.getHours() - 12
        : this.latest_updated_date.getHours();
      const hr_str = String(hr);

      const min = this.latest_updated_date.getMinutes();
      const min_str = min < 10
        ? `0${min}`
        : `${min}`;

      return `${hr_str}:${min_str} ${am_pm}`
    }
  },
});