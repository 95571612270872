import Vue from 'vue';
import store from '@/store';
import Heart from '../Heart.vue'
  
export default Vue.extend({
  props: {
    id: {type: Number, required: true},
    name: {type: String, default: ``},
    is_open: {type: Boolean, default: false},
    // is_favorite: {type: Boolean, default: false},
    wait_time: {type: Number, default: null},
    is_favorite_page: {type: Boolean, default: false},
  },
  computed: {
    is_favorite(): boolean {
      return Boolean(store.state.favorite_ids[this.id])
    }
  },
  methods: {
    toggleFavoriteId(id: number): void {
      store.dispatch(`toggleFavoriteId`, id);
    }
  },
  components: {Heart}
});