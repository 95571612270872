import Vue, { ComponentOptions } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as base_components from './components/base_components'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: `AIzaSyDwXxK6SHYDNIuOCpGTyPyCbapPYWrPerc`,
  authDomain: `disneyland-waits.firebaseapp.com`,
  projectId: `disneyland-waits`,
  storageBucket: `disneyland-waits.appspot.com`,
  messagingSenderId: `373684225574`,
  appId: `1:373684225574:web:72557316a82383911308e7`,
  measurementId: `G-V9TH3RGMHX`
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// console.log(app)
// console.log(analytics)

Vue.config.productionTip = false

for(const name of Object.keys(base_components)) {
  const component = (base_components as Record<string, unknown>)[name] as ComponentOptions<Vue>;
  Vue.component(name, component);
}

new Vue({
  router,
  store,
  render: fn => fn(App)
}).$mount(`#app`)
