import Vue from 'vue';
import store from './store';
import axios from 'axios';
import Heart from '../src/components/Heart.vue';
  
export default Vue.extend({
  data() {
    return {
      fetch_times_interval: null as ReturnType<typeof setInterval> | null,
    }
  },
  mounted(): void {
    this.fetchTimes();

    this.fetch_times_interval = setInterval(() => {
      this.updateCurrentDate();

      const hours = new Date().getHours();

      if (hours < 8) { return; }
      if (hours > 22) { return; }
      if (store.getters.minutes_since_last_update < 6) { return; }

      this.fetchTimes();
    }, 30000);
  },
  beforeDestroy(): void {
    if (this.fetch_times_interval){
      clearInterval(this.fetch_times_interval);
    }
  },
  methods: {
    updateCurrentDate(): void {
      store.dispatch(`updateCurrentDate`);
    },
    async fetchTimes(): Promise<void> {
      try {
        const time = new Date().getTime();
        const res = await axios(`https://storage.googleapis.com/disneyland-waits.appspot.com/disneyland_wait_times.json?cache_key=${time}`)
        console.log(`fetchTimes`, `https://storage.googleapis.com/disneyland-waits.appspot.com/disneyland_wait_times.json?cache_key=${time}`, new Date().toString());
        store.dispatch(`setWaitTimes`, res.data);
        console.log(`fetchTimes res:`, res.data);
      } catch (error) {
        console.log(`fetchTimes error`, error);
      }
    }
  },
  components: { Heart }
});