import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { getAnalytics, logEvent } from "firebase/analytics";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: `/`,
    name: `Browse`,
    component: () => import(`../views/Browse/Browse.vue`)
  },
  {
    path: `/search`,
    name: `Search`,
    component: () => import(`../views/Search/Search.vue`)
  },
  {
    path: `/favorites`,
    name: `Favorites`,
    component: () => import(`../views/Favorites/Favorites.vue`)
  },
  {
    path: `/donate`,
    name: `Donate`,
    component: () => import(`../views/Donate/Donate.vue`)
  },
]

const router = new VueRouter({
  mode: `history`,
  base: process.env.BASE_URL,
  routes
})

router.afterEach(( to ) => {
  const analytics = getAnalytics();
  logEvent(analytics, `page_view`, {
    page_title: to.name || `Unnmaed Route`,
    page_path: to.fullPath,
    page_location: window.location.href
  });
});

export default router
