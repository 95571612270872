/* eslint-disable no-console */

import { register } from 'register-service-worker'
// import axios from 'axios'

// const fetchTimes = async () => {
//   try {
//     const res = await axios(`https://storage.googleapis.com/disneyland-waits.appspot.com/disneyland_wait_times.json`)
//     console.log(`fetchTimes res:`, res)
//   } catch (error) {
//     console.log(`fetchTimes error`, error);
//   }
// };

// const requestNotificationPermission = () => {
//   return new Promise((resolve, reject) => {
//     if (!(`Notification` in window)) {
//       reject(`Notification API not supported!`);
//       return;
//     }
    
//     Notification.requestPermission((result) => {
//       resolve(result);
//     });
//   });
// }

// const persistentNotification = (msg = `default message`) => {
//   if (!(`Notification` in window) || !(`ServiceWorkerRegistration` in window)) {
//     alert(`Persistent Notification API not supported!`);
//     return;
//   }
  
//   try {
//     navigator.serviceWorker.getRegistration()
//       .then((reg) => {
//         if (!reg) { return; }
//         reg.showNotification(msg)
//       })
//       .catch((err) => alert(`Service Worker registration error: ` + err));
//   } catch (err) {
//     alert(`Notification API error: ` + err);
//   }
// }

register(`${process.env.BASE_URL}service-worker.js`, {
  async ready () {
    console.log(
      `App is being served from cache by a service worker.\n` +
      `For more details, visit https://goo.gl/AFskqB`
    )

    // const permission_result = await requestNotificationPermission();
    // console.log(`!!! permission_result`, permission_result);

    // await fetchTimes();
    
    // setInterval(async () => {
    //   await fetchTimes();
    //   // persistentNotification(`updated at ${new Date().toTimeString()}`);
    // }, 30000);
  },
  registered () {
    console.log(`Service worker has been registered.`)
  },
  cached () {
    console.log(`Content has been cached for offline use.`)
  },
  updatefound () {
    console.log(`New content is downloading.`)
  },
  updated () {
    console.log(`New content is available; please refresh.`)
    window.location.reload();
  },
  offline () {
    console.log(`No internet connection found. App is running in offline mode.`)
  },
  error (error) {
    console.error(`Error during service worker registration:`, error)
  }
})
